<template>
  <div>
    <VTitle :title="$t('app.collections', 2)" class="section-title" />

    <VList
      :rows="rows"
      :headers="headers"
      display-pagination
      hide-header
      :is-loading="isLoading"
      :pagination="pagination"
      @click:row="onClickRedirectToCollection"
      @update:current_page="onUpdateCurrentPage"
      @update:per_page="onUpdatePerPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :color="item.colour" :name="item.displayed_name" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <div>
          <div class="font-medium whitespace-nowrap">
            {{ item.displayed_name }}
          </div>

          <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
            {{ item.title }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_status"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import usePaginate from "@/composables/usePaginate";
import useReadMultiple from "@/composables/useReadMultiple";
import useTexts from "@/composables/useTexts";
// Components
import VTitle from "@/components/VTitle";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VList from "@/components/tables/VList";

export default {
  components: {
    VTitle,
    VChip,
    VList,
    VImage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.name"),
        value: "name"
      },
      {
        text: t("app.version"),
        value: "version"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();
    const { getText } = useTexts();

    // Computed
    const rows = computed(() => {
      return data.value?.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        displayed_name: getText(row?.texts, "name"),
        displayed_status: row.status ? t(`app.${row.status}`) : "",
        displayed_avatar: getAvatarURL(row?.avatar?.view_path)
      }));
    });
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.collections", 2)} - ${t("app.roles", 2)} - ${t("app.teaching")}`;

      const name = props.resource?.name;
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.roles.collections",
        pathParams: { id: props.id },
        showToaster: false
      });
    };

    const onClickRedirectToCollection = row => {
      router.push({
        name: "teaching-collections-overview",
        params: { id: row?.id }
      });
    };

    // eslint-disable-next-line
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      rows,
      headers,
      onClickRedirectToCollection,
      // useReadMultiple
      data,
      isLoading,
      pagination,
      // usePaginate
      onUpdatePerPage,
      onUpdateCurrentPage,
      // useColor
      getStatusColor
    };
  }
};
</script>
